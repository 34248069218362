import logo from './roots-logo2.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header>
      <div className="multi-header">
          <h1>TerraformLogic</h1>
          <h4>Blockchain technology, practical use cases.</h4>
      </div>

      <img src={logo} className="App-logo" alt="logo" />
     
      </header>
      <main>
        <section className="vision-section">
          <hr />
          <h2>Vision</h2>
          <p>
            Our goal at TerraFormLogic is to build accessible, useful, and intuitive blockchain technologies. We aim to create accessibility 
            by leveraging the low cost of proof-of-stake platforms. Further, we focus on minimizing cost, simplifying user interface, and vertically 
            integrating the app experience. The use cases for our products revolve around expanding blockchain functionalities without creating additional 
            complexity. The ultimate goal of all our products is to increase utilization of blockchain technologies and help accelerate the distribution 
            of benefits to as many people as possible. Intuitive means easy to learn, and easy to use. The learning curve is the biggest barrier 
            to entry for blockchain applications; thus, our primary focus is to create intuitive products that engage users 
            without complex and lengthy tutorials. Our current project focuses on improving the NFT experience while lowering costs 
            to allow everyone to directly participate in minting, trading, and sharing their creations. 
          </p>
        </section>
      </main>
    </div>
  );
}

export default App;
